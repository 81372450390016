<template>
  <div>
    <v-form @submit.stop.prevent="submit()" ref="form" class="mt-5">
      <div>
        <SelectBriefingLanguageComponent
          @handleChangeLanguage="(e) => (selectedLanguage = e)"
        />
      </div>

      <v-textarea
        outlined
        :label="$t('overview')"
        rows="3"
        v-model="financial.FinancialOverview[selectedLanguage]"
      ></v-textarea>

      <v-card-actions class="pl-0 dxa_modal_actions">
        <v-btn
          class="dxa_modal_btnSuccess"
          color="primary"
          min-width="100"
          :loading="loading"
          type="submit"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          class="dxa_modal_btnError"
          color="secondary"
          min-width="100"
          @click="$router.go(-1)"
          >{{ $t("go_back") }}</v-btn
        >
      </v-card-actions>
    </v-form>

    <div class="text-center mt-8">
      <v-row>
        <h4 class="h4 mx-auto">{{ $tc("manage_financial_field", 2) }}</h4>
        <v-btn
          class="mb-2"
          color="primary"
          rounded
          @click="add_analysis_dialog()"
          fab
          right
        >
          <v-icon large>mdi-plus</v-icon>
        </v-btn>
      </v-row>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $t("title") }}</th>
          <th class="text-center">{{ $t("edit") }}</th>
          <th class="text-center">{{ $t("remove") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in analysis.filter((x) => x.Type == 1)"
          :key="index"
        >
          <td>
            <div v-if="gs.isJson(item.Title)">
              <p
                class="mb-1"
                v-for="(k, i) in Object.keys(JSON.parse(item.Title))"
                :key="i"
              >
                <b style="font-weight: bold">{{ `${k.toUpperCase()}: ` }}</b
                >{{ `${JSON.parse(item.Title)[k]}` }}
              </p>
            </div>
            <span v-else>{{ item.Title }}</span>
          </td>

          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_analysis_dialog(item)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(item)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <ManageAnalysis
        v-if="dialog"
        @close="dialog = false"
        @reset="reset_dialog"
        :CompanyId="company_prop.CompanyId"
        :BriefingId="BriefingId"
        :IndexOptions="index_options"
        :Analysis="analysis_obj"
        :AnalysisType="1"
      />
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Title"
        @close="delete_dialog = false"
        @delete="remove_analysis"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ManageAnalysis from "./Dialogs/ManageAnalysis.vue";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import SelectBriefingLanguageComponent from "../components/SelectBriefingLanguageComponent";

export default {
  name: "ManageCompanyFinancial",

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    financial: {
      CompanyId: null,
      FinancialOverview: {},
    },
    analysis: [],
    analysis_obj: null,
    selectedLanguage: null,
  }),

  components: {
    ManageAnalysis,
    DeleteConfirmationModal,
    SelectBriefingLanguageComponent,
  },

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    index_options() {
      var i;
      var return_list = [];
      for (i = 1; i <= this.analysis.filter((x) => x.Type == 1).length; i++) {
        return_list.push(i);
      }
      if (!this.analysis_obj) {
        return_list.push(this.analysis.filter((x) => x.Type == 1).length + 1);
      }
      return return_list;
    },
  },
  props: {
    BriefingId: Number,
    company_prop: Object,
  },

  async created() {
    this.get_analysis();
  },
  methods: {
    refresh_analysis_type() {
      this.analysis = this.company_prop.CompanyAnalysis.filter(
        (x) => x.Type == 1
      );
    },
    get_analysis() {
      if (this.company_prop != null) {
        this.refresh_analysis_type();
        if (this.company_prop.FinancialOverview !== null) {
          if (this.gs.isJson(this.company_prop.FinancialOverview)) {
            this.financial.FinancialOverview = JSON.parse(
              this.company_prop.FinancialOverview
            );
          } else {
            var text = this.company_prop.FinancialOverview;
            this.company_prop.FinancialOverview = {};
            this.company_prop.FinancialOverview[this.selectedLanguage] = text;
            this.financial.FinancialOverview[this.selectedLanguage] =
              this.company_prop.FinancialOverview[this.selectedLanguage];
          }
        } else {
          this.financial.FinancialOverview[this.selectedLanguage] = "";
        }

        this.financial.BriefingId = this.BriefingId;
      }
    },
    submit: async function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.error = null;

      this.company_prop.FinancialOverview = this.financial.FinancialOverview;

      var obj = JSON.parse(JSON.stringify(this.financial));
      obj.FinancialOverview = JSON.stringify(obj.FinancialOverview);

      await this.apiService
        .putRequest("companybriefing/financial/edit", obj)
        .then((result) => {
          this.$emit("overview", this.financial.FinancialOverview);
          this.$emit("alert", true, this.$t("saved"));
        })
        .catch((error) => {
          this.$emit("alert", false, error.body.message);
        });
      this.loading = false;
    },
    open_delete_dialog: function (analysis) {
      this.delete_obj = analysis;
      this.delete_dialog = true;
    },
    remove_analysis: async function (obj) {
      this.loading = true;
      this.error = null;
      await this.apiService
        .deleteRequest(`companyanalysis/delete/${obj.Id}`)
        .then((result) => {
          var objs = JSON.parse(result.message);
          this.$emit("reset", objs);
          this.get_analysis();
          this.delete_dialog = false;
        })
        .catch((error) => {
          this.$emit("error", error.body.message);
          this.error = error.body.message;
        });
      this.loading = false;
    },
    add_analysis_dialog: function () {
      this.analysis_obj = null;
      this.dialog = true;
    },
    edit_analysis_dialog: function (obj) {
      this.analysis_obj = obj;
      this.dialog = true;
    },
    reset_dialog(objs) {
      this.$emit("reset", objs);
      this.refresh_analysis_type();
      this.dialog = false;
    },
  },
};
</script>
